<template>
  <!-- <HeaderLayout v-show="true" /> -->

  <div class="container-sm">
    <div class="row">
      <div class="offset-sm-3 col-md-6 border bg-white">
        <div class="d-flex justify-content-between align-items-baseline">
          <h4 class="mt-3">Pendaftaran Peserta</h4>
          <button class="btn btn-sm btn-warning" @click="showSNK()">
            Lihat Syarat Pendaftaran
          </button>
        </div>
        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>
        <div v-if="!isRegistrationOpen()" class="row">
          <div class="col-12">
            <div
              class="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <h5 class="alert-heading">
                <i class="fa-solid fa-circle-info"></i>&nbsp;Pendaftaran Belum
                Dibuka!
              </h5>
              <p>
                Pendaftaran akan dimulai pada tanggal
                {{
                  useMoment(registrationDate.start)
                    .locale("id")
                    .format("dddd, D MMMM y")
                }}
              </p>
              <button class="btn btn-sm btn-warning" @click="showSNK()">
                Lihat Syarat Pendaftaran
              </button>
              <!-- <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button> -->
            </div>
          </div>
        </div>
        <div v-else-if="isRegistrationClosed()" class="row">
          <div class="col-12">
            <div
              class="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              <h5 class="alert-heading">
                <i class="fa-solid fa-circle-info"></i>&nbsp;Pendaftaran Ditutup
              </h5>
              <p>
                Bagi peserta musabaqoh yang sudah diverifikasi, dapat memperoleh
                informasi jadwal-jadwal lomba melalui grup WA MHQ berikut :
              </p>
              <a
                href="https://chat.whatsapp.com/LI1I923EgCnHoyrZkDV7N9"
                class="btn btn-success"
                >Gabung Grup
              </a>
              <!-- <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button> -->
            </div>
          </div>
        </div>
        <form @submit="onSubmit">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="mt-2 mb-4">
                <label for="nama-lengkap" class="form-label"
                  >Nama Lengkap
                  <span class="text-danger small">(wajib diisi)</span>
                </label>
                <input
                  type="text"
                  v-model="data.full_name"
                  placeholder="Nama Lengkap"
                  class="form-control form-control-sm"
                  :class="{ 'is-invalid': errors.full_name }"
                  id="nama-lengkap"
                />
                <div class="invalid-feedback" v-if="errors.full_name">
                  {{ errors.full_name[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="alamat-lengkap" class="form-label"
                  >Alamat Lengkap
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <textarea
                  class="form-control form-control-sm"
                  v-model="data.address"
                  id="alamat-lengkap"
                  placeholder="Alamat"
                  rows="3"
                  :class="{ 'is-invalid': errors.address }"
                ></textarea>
                <div class="invalid-feedback" v-if="errors.address">
                  {{ errors.address[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="tanggal-lahir" class="form-label"
                  >Tanggal Lahir
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <datepicker
                  class="form-control form-control-sm"
                  placeholder="Tahun-Bulan-Tanggal"
                  :class="{ 'is-invalid': errors.bod }"
                  v-model="dates.bod"
                  :disabled-dates="{ predicate: disabledDates }"
                />
                <div
                  class="invalid-feedback small"
                  :class="{ 'd-block': errors.bod }"
                  v-if="errors.bod"
                >
                  {{ errors.bod[0] }}
                </div>
                <div class="valid-feedback d-block small">
                  {{ this.currentAgePrint }}
                </div>
              </div>
              <div class="mb-4">
                <label class="form-label"
                  >Jenis Kelamin
                  <span class="text-danger small">(wajib diisi)</span>
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="data.gender"
                    name="gender"
                    value="male"
                    id="ikhwan"
                    :checked="data.gender == 'male'"
                  />
                  <label class="form-check-label" for="ikhwan"> Ikhwan </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="data.gender"
                    name="gender"
                    value="female"
                    id="akhwat"
                    :checked="data.gender == 'female'"
                  />
                  <label class="form-check-label" for="akhwat"> Akhwat </label>
                </div>
                <div
                  class="invalid-feedback"
                  :class="{ 'd-block': errors.gender }"
                  v-if="errors.gender"
                >
                  {{ errors.gender[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="rapot-akte" class="form-label"
                  >Foto Akte Lahir
                  <span class="text-danger small d-block"
                    >(salah satu wajib ada)</span
                  ></label
                >
                <input
                  type="file"
                  ref="birth_certificate"
                  @change="previewFile($event, 'birth_certificate')"
                  @blur="previewFile($event, 'birth_certificate')"
                  accept=".png,.jpg,.jpeg,.JPEG,.pdf"
                  :class="{ 'is-invalid': errors.birth_certificate }"
                  class="form-control form-control-sm"
                  id="rapot-akte"
                />
                <div
                  class="invalid-feedback"
                  :class="{ 'd-block': errors.birth_certificate }"
                  v-if="errors.birth_certificate"
                >
                  {{ errors.birth_certificate[0] }}
                </div>
              </div>

              <!-- <div class="mb-4">
                <label for="rapot-akte" class="form-label"
                  >Scan/Foto Rapor Sekolah
                  <span class="text-danger small d-block"
                    >(salah satu wajib ada)</span
                  ></label
                >
                <input
                  type="file"
                  class="form-control form-control-sm"
                  @change="previewFile($event, 'school_report_card')"
                  :class="{ 'is-invalid': errors.school_report_card }"
                  id="rapot-akte"
                />
              </div> -->
              <div class="mb-4">
                <label for="ktp" class="form-label"
                  >Foto KTP
                  <span class="text-danger small d-block"
                    >(salah satu wajib ada)</span
                  ></label
                >
                <input
                  type="file"
                  ref="id_card"
                  class="form-control form-control-sm"
                  accept=".png,.jpg,.jpeg,.JPEG,.pdf"
                  @change="previewFile($event, 'id_card')"
                  :class="{ 'is-invalid': errors.id_card }"
                  id="ktp"
                />
                <div
                  class="invalid-feedback"
                  :class="{ 'd-block': errors.id_card }"
                  v-if="errors.id_card"
                >
                  {{ errors.id_card[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="kk" class="form-label"
                  >Foto Kartu Keluarga
                  <span class="text-danger small d-block"
                    >(salah satu wajib ada)</span
                  ></label
                >
                <input
                  type="file"
                  ref="family_card"
                  class="form-control form-control-sm"
                  accept=".png,.jpg,.jpeg,.JPEG,.pdf"
                  @change="previewFile($event, 'family_card')"
                  :class="{ 'is-invalid': errors.family_card }"
                  id="kk"
                />
                <div
                  class="invalid-feedback"
                  :class="{ 'd-block': errors.family_card }"
                  v-if="errors.family_card"
                >
                  {{ errors.family_card[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="surat-rekomendasi" class="form-label"
                  >Foto Surat Rekomendasi Sekolah
                  <span class="text-muted small d-block"
                    >(opsional)</span
                  ></label
                >
                <input
                  type="file"
                  ref="school_recommendation_letter"
                  class="form-control form-control-sm"
                  accept=".png,.jpg,.jpeg,.JPEG,.pdf"
                  :class="{ 'is-invalid': errors.school_recommendation_letter }"
                  @change="previewFile($event, 'school_recommendation_letter')"
                  id="surat-rekomendasi"
                />
                <div
                  class="invalid-feedback"
                  :class="{ 'd-block': errors.school_recommendation_letter }"
                  v-if="errors.school_recommendation_letter"
                >
                  {{ errors.school_recommendation_letter[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="kategori-lomba" class="form-label"
                  >Kategori Lomba
                  <span class="text-danger small">(wajib ada)</span></label
                >
                <div class="form-check">
                  <input
                    ref="cc_tahfiz"
                    value="tahfiz"
                    class="form-check-input"
                    type="radio"
                    name="contest_category"
                    v-model="data.contest_category"
                    id="contest-category-1"
                    :checked="data.contest_category == 'tahfiz'"
                  />
                  <label class="form-check-label" for="contest-category-1">
                    Tahfidzul Qur'an
                  </label>
                </div>
                <div class="form-check">
                  <input
                    ref="cc_tilawah"
                    value="tilawah"
                    class="form-check-input"
                    type="radio"
                    name="contest_category"
                    v-model="data.contest_category"
                    id="contest-category-2"
                    :checked="data.contest_category == 'tilawah'"
                  />
                  <label class="form-check-label" for="contest-category-2">
                    Tilawah
                  </label>
                </div>
              </div>
              <div class="row" v-if="data.contest_category == 'tahfiz'">
                <!-- <div class="col-6 mb-4">
                <label for="jumlah-juz" class="form-label">Jumlah Juz</label>
                <div class="form-check">
                  <input
                    value="5"
                    class="form-check-input"
                    type="radio"
                    name="tahfiz_category"
                    v-model="data.tahfiz_category"
                    :checked="data.tahfiz_category == '5'"
                    id="5juz"
                  />
                  <label class="form-check-label" for="5juz"> 5 Juz </label>
                </div>
                <div class="form-check">
                  <input
                    value="10"
                    class="form-check-input"
                    type="radio"
                    name="tahfiz_category"
                    v-model="data.tahfiz_category"
                    :checked="data.tahfiz_category == '10'"
                    id="10juz"
                  />
                  <label class="form-check-label" for="10juz"> 10 Juz </label>
                </div>
                <div class="form-check">
                  <input
                    value="20"
                    class="form-check-input"
                    type="radio"
                    name="tahfiz_category"
                    v-model="data.tahfiz_category"
                    :checked="data.tahfiz_category == '20'"
                    id="20juz"
                  />
                  <label class="form-check-label" for="20juz"> 20 Juz </label>
                </div>
                <div class="form-check">
                  <input
                    value="30"
                    class="form-check-input"
                    type="radio"
                    name="tahfiz_category"
                    v-model="data.tahfiz_category"
                    :checked="data.tahfiz_category == '30'"
                    id="30juz"
                  />
                  <label class="form-check-label" for="30juz"> 30 Juz </label>
                </div>
                <div
                  class="invalid-feedback"
                  :class="{ 'd-block': errors.tahfiz_category }"
                >
                  Kategori Juz tidak boleh kosong
                </div>
              </div> -->
                <div class="mb-4 col-12">
                  <label for="jumlah-juz" class="form-label"
                    >Pilihan Juz
                    <!-- <small class="text-danger">(Jika Pilih 5 Juz)</small> -->
                    <span class="text-danger small">(wajib diisi)</span>
                  </label>
                  <div
                    class="form-check"
                    v-for="(item, index) in contest_category.tahfiz.category"
                    :key="index"
                  >
                    <input
                      :value="item.value"
                      ref="tahfiz_category"
                      class="form-check-input"
                      type="radio"
                      name="tahfiz_category"
                      v-model="data.tahfiz_category"
                      :checked="data.tahfiz_category == item.value"
                      :id="`to-category-${index}}`"
                    />
                    <label
                      class="form-check-label"
                      :for="`to-category-${index}}`"
                    >
                      {{ item.name }}</label
                    >
                  </div>
                  <div
                    class="invalid-feedback"
                    :class="{ 'd-block': errors.tahfiz_option }"
                    v-if="errors.tahfiz_option"
                  >
                    {{ errors.tahfiz_option[0] }}
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <label for="nomor-kontak" class="form-label"
                  >Nomor Kontak Peserta
                  <span class="text-danger small text-danger"
                    >(wajib diisi)</span
                  >
                </label>
                <input
                  type="text"
                  v-model="data.phone_number"
                  placeholder="Nomor Telepon"
                  class="form-control form-control-sm"
                  :class="{ 'is-invalid': errors.phone_number }"
                  id="nomor-kontak"
                />
                <div class="invalid-feedback" v-if="errors.phone_number">
                  {{ errors.phone_number[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="nomor-whatsapp" class="form-label"
                  >Nomor Whatsapp Peserta
                  <span class="text-danger small text-danger"
                    >(wajib diisi)</span
                  ></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Nomor Whatsapp"
                  :class="{ 'is-invalid': errors.whatsapp_number }"
                  id="nomor-whatsapp"
                  v-model="data.whatsapp_number"
                />
                <div class="invalid-feedback" v-if="errors.whatsapp_number">
                  {{ errors.whatsapp_number[0] }}
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="mt-2 mb-4">
                <label for="nama-lengkap-ayah" class="form-label"
                  >Nama Lengkap Ayah
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Nama Lengkap Ayah"
                  id="nama-lengkap-ayah"
                  :class="{ 'is-invalid': errors.father_name }"
                  v-model="data.father_name"
                />
                <div class="invalid-feedback" v-if="errors.father_name">
                  {{ errors.father_name[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="pekerjaan-ayah" class="form-label"
                  >Pekerjaan Ayah
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <input
                  type="text"
                  placeholder="Pekerjaan Ayah"
                  class="form-control form-control-sm"
                  v-model="data.father_occupation"
                  :class="{ 'is-invalid': errors.father_occupation }"
                  id="pekerjaan-ayah"
                />
                <div class="invalid-feedback" v-if="errors.father_occupation">
                  {{ errors.father_occupation[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="asal-sekolah" class="form-label"
                  >Asal Sekolah
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="asal-sekolah"
                  placeholder="Asal Sekolah"
                  v-model="data.school_origin"
                  :class="{ 'is-invalid': errors.school_origin }"
                />
                <div class="invalid-feedback" v-if="errors.school_origin">
                  {{ errors.school_origin[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="kategori-sekolah" class="form-label"
                  >Kategori Sekolah
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <select
                  id="kategori-sekolah"
                  class="form-select form-select-sm"
                  placeholder="Kategori Sekolah"
                  v-model="data.school_category"
                  :class="{ 'is-invalid': errors.school_category }"
                >
                  <option selected value="Sekolah">Sekolah</option>
                  <option value="Madrasah">Madrasah</option>
                  <option value="Pesantren">Pesantren</option>
                </select>
                <div class="invalid-feedback" v-if="errors.school_category">
                  {{ errors.school_category[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="alamat-sekolah" class="form-label"
                  >Alamat Sekolah
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <textarea
                  class="form-control form-control-sm"
                  id="alamat-sekolah"
                  placeholder="Alamat Sekolah"
                  v-model="data.school_address"
                  rows="3"
                  :class="{ 'is-invalid': errors.school_address }"
                ></textarea>
                <div class="invalid-feedback" v-if="errors.school_address">
                  {{ errors.school_address[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="pengalaman-lomba" class="form-label"
                  >Pengalaman Lomba
                  <span class="text-danger small text-muted"
                    >(opsional)</span
                  ></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Pengalaman Lomba"
                  :class="{ 'is-invalid': errors.contest_experience_1 }"
                  id="pengalaman-lomba"
                  v-model="data.contest_experience_1"
                />
              </div>
              <div class="mb-4">
                <label for="pengalaman-lomba-2" class="form-label"
                  >Pengalaman Lomba #2
                  <span class="text-danger small text-muted"
                    >(opsional)</span
                  ></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Pengalaman Lomba"
                  id="pengalaman-lomba-2"
                  v-model="data.contest_experience_2"
                  :class="{ 'is-invalid': errors.contest_experience_2 }"
                />
              </div>
              <div class="mb-4">
                <label for="nama-wali" class="form-label"
                  >Nama Wali Pendamping
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="nama-wali"
                  placeholder="Nama Wali Pendamping"
                  v-model="data.mentor_name_1"
                  :class="{ 'is-invalid': errors.mentor_name_1 }"
                />
                <div class="invalid-feedback" v-if="errors.mentor_name_1">
                  {{ errors.mentor_name_1[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="nama-wali-2" class="form-label"
                  >Nama Wali Pendamping #2
                  <span class="text-danger small text-muted"
                    >(opsional)</span
                  ></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="data.mentor_name_2"
                  placeholder="Nama Wali Pendamping"
                  id="nama-wali-2"
                  :class="{ 'is-invalid': errors.mentor_name_2 }"
                />
              </div>
              <div class="mb-4">
                <label for="nomor-wali" class="form-label"
                  >Nomor Kontak Wali
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="data.mentor_number"
                  placeholder="Nomor Wali"
                  :class="{ 'is-invalid': errors.mentor_number }"
                  id="nomor-wali"
                />
                <div class="invalid-feedback" v-if="errors.mentor_number">
                  {{ errors.mentor_number[0] }}
                </div>
              </div>
              <div class="mb-4">
                <label for="email" class="form-label"
                  >Email
                  <span class="text-danger small">(wajib diisi)</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="email"
                  placeholder="Email"
                  v-model="data.email"
                  :class="{ 'is-invalid': errors.email }"
                  aria-describedby="emailHelp"
                />
                <div class="invalid-feedback" v-if="errors.email">
                  {{ errors.email[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <hr />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="rule-1"
                  value="1"
                  :class="{ 'is-invalid': errors.rule_obey_confirm_1 }"
                  v-model="checks.rule_obey_confirm_1"
                />
                <label class="form-check-label" for="rule-1">
                  Saya bersedia mengikuti peraturan perlombaan MTQ
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="1"
                  v-model="checks.rule_obey_confirm_2"
                  :class="{ 'is-invalid': errors.rule_obey_confirm_2 }"
                  id="rule-2"
                />
                <label class="form-check-label" for="rule-2">
                  Saya bersedia didiskualifikasi, jika melanggar peraturan yang
                  ditetapkan panitia
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <!-- <div class="col">
            <label class="font-red"
              >Jika Anda mengalami kendala dalam proses pendaftaran, silahkan
              hubungi kami melalui Whatsapp di nomor:</label
            >
            <br />
            <label class="font-blue">0821-6741-7647</label>
          </div> -->
          </div>
          <div class="row mt-3 mb-4">
            <div class="col">
              <button
                class="btn btn-primary"
                type="submit"
                :disabled="
                  $store.getters.getLoadingStatus ||
                  !isRegistrationOpen() ||
                  isRegistrationClosed()
                "
              >
                Daftar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- <FooterLayout /> -->
</template>

<script>
// import HeaderLayout from "@/components/layout/HeaderLayout.vue";
// import FooterLayout from "@/components/layout/FooterLayout.vue";
import Datepicker from "vue3-datepicker";
import moment from "moment";
import CandidateService from "@/services/candidate.service";
import snk from "@/assets/image/snk-2024.jpeg";
import { calcDate } from "@/_helper/date_helper";

const candidateService = new CandidateService();

export default {
  name: "RegisterCandidate",
  components: {
    // HeaderLayout,
    // FooterLayout,
    Datepicker
  },
  watch: {
    "data.contest_category"(newValue, oldValue) {
      if (newValue != oldValue) {
        this.data.tahfiz_category = "";
        this.data.tahfiz_option = "";
      }

      if (newValue === "tilawah") {
        if (this.currentAge > this.contest_category.tilawah.maximumAge) {
          this.$swal.fire({
            icon: "error",
            text: "Usia pendaftar telah melewati batas persyaratan usia kategori lomba!"
          });
          this.$refs["cc_tahfiz"].click();
        } else {
          delete this.data.tahfiz_category;
          delete this.data.tahfiz_option;
        }
      }
    },
    "data.tahfiz_category"(newValue, oldValue) {
      if (
        newValue != oldValue &&
        newValue != "" &&
        this.data.contest_category == "tahfiz"
      ) {
        let tahfizes = this.contest_category.tahfiz.category;
        let tahfiz = tahfizes.find((e) => e.value == newValue);
        this.data.tahfiz_option = tahfiz.option;

        if (this.currentAge > tahfiz.maximumAge) {
          this.$swal.fire({
            icon: "error",
            text: "Usia pendaftar telah melewati batas persyaratan usia kategori lomba!"
          });
          console.log(this.$refs.tahfiz_category);
          for (const e of this.$refs["tahfiz_category"]) {
            e.checked = false;
          }
          this.data.tahfiz_category = "";
          this.data.tahfiz_option = "";
        }
      }
    },
    "dates.bod"(newValue, oldValue) {
      console.log(oldValue, newValue);
      if (newValue != oldValue) {
        console.log(newValue, this.officialDate);
        this.currentAge = moment(this.officialDate).diff(
          moment(newValue),
          "years",
          true
        );

        const currentAgePrint = calcDate(this.officialDate, moment(newValue));
        this.currentAgePrint = `${currentAgePrint.year} Tahun, ${currentAgePrint.month} Bulan, ${currentAgePrint.day} Hari`;

        this.contest_category.tahfiz.maximumAge;

        // console.log("is allowed age", this.contest_category.tahfiz);
        console.log("current age", this.currentAge);
        this.data.bod = moment(newValue).format("Y-MM-DD");

        this.$refs["cc_tahfiz"].click();
        this.data.tahfiz_category = "";
        this.data.tahfiz_option = "";
      }
    },
    "checks.rule_obey_confirm_1"(newValue) {
      if (newValue) {
        this.data.rule_obey_confirm_1 = 1;
      }
    },
    "checks.rule_obey_confirm_2"(newValue) {
      if (newValue) {
        this.data.rule_obey_confirm_2 = 1;
      }
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.isRegistrationOpen() || this.isRegistrationClosed())
        return null;

      this.$store.dispatch("setLoader", true);
      candidateService
        .postCandidate(this.data)
        .then((response) => {
          let piljuz = "";
          let kategori_juz = "";
          if (response.data.result.pilihan_juz) {
            piljuz =
              "<tr>" +
              '<td style="padding-right:1vw; text-align:right;width:40%;">Pilihan Juz</td>' +
              '<td style="font-weight:bold;padding-left:1vw; text-align:left;width:60%;">' +
              response.data.result.pilihan_juz +
              "</td>" +
              "</tr>";
          }
          if (response.data.result.kategori_juz) {
            kategori_juz =
              "<tr>" +
              '<td style="padding-right:1vw; text-align:right;width:40%;">Kategori Juz</td>' +
              '<td style="font-weight:bold;padding-left:1vw; text-align:left;width:60%;">' +
              response.data.result.kategori_juz +
              "</td>" +
              "</tr>";
          }
          this.$swal.fire({
            icon: "success",
            title: "Pendaftaran Berhasil",
            html:
              '<table style="width:100%;font-size:12pt;">' +
              "<tr>" +
              '<td style="padding-right:1vw; text-align:right;width:40%;">No. Pendaftaran</td>' +
              '<td style="font-weight:bold;padding-left:1vw; text-align:left;width:60%;">' +
              response.data.result.nomor_pendaftaran +
              "</td>" +
              "<tr>" +
              '<td style="padding-right:1vw; text-align:right;width:40%;">Nama</td>' +
              '<td style="font-weight:bold;padding-left:1vw; text-align:left;width:60%;">' +
              response.data.result.nama_lengkap +
              "</td>" +
              "</tr>" +
              "<tr>" +
              '<td style="padding-right:1vw; text-align:right;width:40%;">Sekolah</td>' +
              '<td style="font-weight:bold;padding-left:1vw; text-align:left;width:60%;">' +
              response.data.result.sekolah +
              "</td>" +
              "</tr>" +
              "<tr>" +
              '<td style="padding-right:1vw; text-align:right;width:40%;">Kategori Lomba</td>' +
              '<td style="font-weight:bold;padding-left:1vw; text-align:left;width:60%;">' +
              response.data.result.kategori_lomba +
              "</td>" +
              "</tr>" +
              kategori_juz +
              piljuz +
              "</table>" +
              '<br><p style="font-size:12pt;color:red;">Silahkan lihat email anda untuk detail yang lebih lengkap</p>'
          });

          this.$store.dispatch("setLoader", false);
          this.clearData();
        })
        .catch((e) => {
          let error = null;
          if (e.response.data.errors) {
            Object.keys(e.response.data.errors).every(function (key) {
              error = e.response.data.errors[key][0];
              return false;
            });
          }

          this.$swal.fire({
            icon: "error",
            title: "Gagal",
            text: error ?? "Harap Periksa Data Anda Kembali"
          });

          if (e.response.data.errors) {
            this.errors = e.response.data.errors;
          }

          this.$store.dispatch("setLoader", false);
          // console.log("apaan", );
        })
        .finally(() => {});
      // console.log(this.data);
    },
    previewFile(e, name) {
      // if (e.type == "blur") {
      //   this.data[name] = null;
      // }
      if (
        e.type == "change" &&
        e.target.files.length > 0 &&
        e.target.files[0].size > 5 * 1000000
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Ukuran File tidak boleh dari 5MB"
        });
        this.$refs[name].value = null;
        e.val = null;
      }
      if (e.target.files && e.target.files.length) {
        // if(e.)
        let files = e.target.files;
        this.data[name] = files[0];
      } else {
        this.data[name] = null;
      }
    },
    clearData() {
      this.data = {
        full_name: "",
        address: "",
        father_name: "",
        father_occupation: "",
        bod: null,
        gender: "",
        school_origin: "",
        school_category: "Sekolah",
        school_address: "",
        contest_category: "tahfiz",
        tahfiz_category: null,
        tahfiz_option: null,
        contest_experience_1: "",
        contest_experience_2: "",
        phone_number: "",
        whatsapp_number: "",
        mentor_name_1: "",
        mentor_name_2: "",
        mentor_number: "",
        email: "",
        rule_obey_confirm_1: "",
        rule_obey_confirm_2: ""
      };
    },
    showSNK() {
      this.$swal.fire({
        // title: "Syarat & Ketentuan Pendaftaran",
        imageUrl: snk,
        imageWidth: "100%"
        // imageHeight: 800
      });
    },
    isRegistrationOpen() {
      return moment().isSameOrAfter(this.registrationDate.start, "d");
    },
    isRegistrationClosed() {
      return moment().isAfter(this.registrationDate.end, "d");
    },
    disabledDates(date) {
      return moment(date).isSameOrAfter(this.officialDate, "d");
    }
  },
  data() {
    return {
      useMoment: moment,
      officialDate: new Date("2024-11-01 00:00:00"),
      currentAge: null,
      currentAgePrint: null,
      registrationDate: {
        // start: new Date("2024-09-01"),
        // end: new Date("2024-09-30")
        start: new Date("2024-10-01"),
        end: new Date("2024-10-31")
      },
      contest_category: {
        tahfiz: {
          category: [
            {
              name: "Juz 30",
              value: 1,
              option: 1,
              maximumAge: 11,
              isDisabled: true
            },
            {
              name: "Juz 29 - 30",
              value: 2,
              option: 2,
              maximumAge: 12,
              isDisabled: true
            },
            {
              name: "Juz 28 - 30",
              value: 3,
              option: 3,
              maximumAge: 14,
              isDisabled: true
            },
            {
              name: "Juz 1 - 5",
              value: 5,
              option: 4,
              maximumAge: 15,
              isDisabled: true
            },
            {
              name: "Juz 1 - 10",
              value: 10,
              option: 5,
              maximumAge: 17,
              isDisabled: true
            },
            {
              name: "Juz 1 - 20",
              value: 20,
              option: 6,
              maximumAge: 19,
              isDisabled: true
            },
            {
              name: "Juz 1 - 30",
              value: 30,
              option: 7,
              maximumAge: 22,
              isDisabled: true
            }
          ]
        },
        tilawah: {
          maximumAge: 11,
          isDisabled: true
        }
      },
      isLoading: false,
      errors: {},
      dates: {
        bod: null
      },
      checks: {
        rule_obey_confirm_1: false,
        rule_obey_confirm_2: false
      },
      data: {
        full_name: "",
        address: "",
        father_name: "",
        father_occupation: "",
        bod: new Date(),
        gender: "",
        school_origin: "",
        school_category: "Sekolah",
        school_address: "",
        contest_category: "tahfiz",
        tahfiz_category: "",
        tahfiz_option: "",
        contest_experience_1: "",
        contest_experience_2: "",
        phone_number: "",
        whatsapp_number: "",
        mentor_name_1: "",
        mentor_name_2: "",
        mentor_number: "",
        email: "",
        rule_obey_confirm_1: "",
        rule_obey_confirm_2: ""
      }
    };
  }
};
</script>

<style></style>
